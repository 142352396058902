import React, { Component } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import {
  Navbar,
  NavbarBrand,
  NavbarBurger,
  NavbarEnd,
  NavbarMenu,
  NavbarItem,
  Container,
  Icon,
  Button,
} from "bloomer"

export default class navbar extends Component {
  static propTypes = {
    siteTitle: PropTypes.string.isRequired,
  }
  state = {
    isOpen: false,
  }

  toggleCollapse = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  render() {
    return (
      <Navbar>
        <Container>
        {/* Site title link */}
        <NavbarBrand>

        <Link to="/" className="navbar-item">
          <a class="navbar-item" href="/">
            <img src="https://cdn1.azybao.training/wp-content/uploads/2019/12/13133523/azybaoLogo-W-JH-180702-v6.png" alt="Logo"></img>
          </a>
          </Link>
          <NavbarBurger
              isActive={this.state.isOpen}
              onClick={this.toggleCollapse}
            />
          </NavbarBrand>

          <NavbarMenu
            isActive={this.state.isOpen}
            onClick={this.toggleCollapse}
          >
            {/* Page nav links */}
            <NavbarEnd>
              <Link
                to="/"
                className="navbar-link nav-item is-arrowless"
                activeClassName="is-active"
              >
                Home
              </Link>
              <Link
                to="/page-2"
                activeClassName="is-active"
                className="navbar-link nav-item is-arrowless"
              >
                Page 2
              </Link>
              <Link
                to="/courses"
                activeClassName="is-active"
                className="navbar-link nav-item is-arrowless"
              >
                Courses
              </Link>
              <Link
                to="/join"
                activeClassName="is-active"
                className="navbar-link nav-item is-arrowless"
              >
                Join
              </Link>
              {/* Join as a Member button */}
              <NavbarItem>
                <Button
                  href="https://azybao.training/join"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="is-rounded"
                  isSize="small"
                >
                  <span>Become a Member</span>
                  <Icon className="fab fa-github fa-sm" />
                </Button>
              </NavbarItem>
            </NavbarEnd>
          </NavbarMenu>
        </Container>
      </Navbar>
    )
  }
}
