import React from "react"
import { Link } from "gatsby"
import { Footer, Container, Content, Icon } from "bloomer"

const MainFooter = () => {
  return (
    <Footer>
      <Container>
        {/* Made by Lemedia based on a Starter built by ZLI */}
        <Content>
          <p>
            Made with{" "}
            <Icon hasTextColor="danger" className="fa fa-heart"></Icon> by{" "}
            <a
              href="https://lemedia.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              LeMedia
            </a>
          </p>
        </Content>
        {/* Built with Gatsby, Bulma, and Bloomer */}
        <Content isSize="small">
          <p>
            © {new Date().getFullYear()}, AZYBAO - All Rights Reserved - MDX edition
            {" - "}
            <Link
                to="/legal"
              >
                Legal
              </Link>
            {" - "}
            <Link
                to="/privacy-policy"
              >
                Privacy policy
              </Link>
            {" - "}
            <Link
                to="/contact"
              >
                Contact
              </Link>
          </p>
        </Content>
      </Container>
    </Footer>
  )
}

export default MainFooter
